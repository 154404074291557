.footer {
  background-color: $darkerAmikoos;
  padding: 2em 0 1em;
  text-align: center;

  .footer__navigation {
    display: flex;
    justify-content: space-evenly;

    a {
      color: $white;
      cursor: pointer;
      font-size: 1.2em;

      &:hover {
        color: $amikoosPink;
      }
    }
  }

  p {
    font-size: 0.9em;
    margin-top: 2em;
  }
}
