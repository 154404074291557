@mixin transition($prop1, $prop2, $seconds) {
  @if $prop2 {
    -webkit-transition: $prop1 $seconds cubic-bezier(0.34, 1.56, 0.64, 1),
      $prop2 $seconds cubic-bezier(0.34, 1.56, 0.64, 1);
    -moz-transition: $prop1 $seconds cubic-bezier(0.34, 1.56, 0.64, 1),
      $prop2 $seconds cubic-bezier(0.34, 1.56, 0.64, 1);
    -o-transition: $prop1 $seconds cubic-bezier(0.34, 1.56, 0.64, 1),
      $prop2 $seconds cubic-bezier(0.34, 1.56, 0.64, 1);
    transition: $prop1 $seconds cubic-bezier(0.34, 1.56, 0.64, 1),
      $prop2 $seconds cubic-bezier(0.34, 1.56, 0.64, 1);
  } @else {
    -webkit-transition: $prop1 $seconds cubic-bezier(0.34, 1.56, 0.64, 1);
    -moz-transition: $prop1 $seconds cubic-bezier(0.34, 1.56, 0.64, 1);
    -o-transition: $prop1 $seconds cubic-bezier(0.34, 1.56, 0.64, 1);
    transition: $prop1 $seconds cubic-bezier(0.34, 1.56, 0.64, 1);
  }
}

@mixin animalPosition($position, $top, $width, $right) {
  position: $position;
  top: $top;
  width: $width;
  right: $right;
}

@mixin backgroundCover {
  -ms-background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  -webkit-background-size: cover;
  background-size: cover;
}

@mixin backgroundParallax($url) {
  background-image: url($url);
  background-attachment: fixed;
  background-repeat: no-repeat;
  @include backgroundCover;
}

@mixin backgroundPattern($url) {
  background-image: url($url);
  background-attachment: fixed;
  background-repeat: repeat;
}

@mixin bodyTexts($fontSize) {
  margin: 1.2em 0 2.5em 0;
  font-weight: 400;
  font-size: $fontSize;
  text-shadow: 1px 1px 3px $white;
}

@mixin borderRaius($value) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
}

@mixin boxshadow1() {
  -webkit-box-shadow: 0px 10px 13px -7px #000000,
    -1px 4px 12px 2px rgba(0, 0, 0, 0);
  box-shadow: 0px 10px 13px -7px #000000, -1px 4px 12px 2px rgba(0, 0, 0, 0);
}

@mixin boxshadow2() {
  -webkit-box-shadow: 0px 3px 7px -1px rgba(0, 0, 0, 0.23);
  box-shadow: 0px 3px 7px -1px rgba(0, 0, 0, 0.23);
}
