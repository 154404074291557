/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  /* Styles */
}

/* Smartphones (landscape) ----------- */
@media only screen and (min-width: 321px) {
  /* Styles */
}

/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 320px) {
  /* Styles */
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  /* Styles */
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  /* Styles */
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  /* Styles */
}

/* Desktops and laptops ----------- */
@media only screen and (max-width: 670px) {
  /* Styles */
  .slider .slider__container img {
    max-width: 23em !important;
  }
  .guTiMg {
    max-height: 290px;
  }
  .footer {
    .footer__navigation {
      display: block !important;
      li {
        padding: 0.3em;
      }
    }
  }
  .header__container {
    .header__search--background {
      .header___search {
        display: flex !important;
        justify-content: center !important;

        .header__imgAndSearch {
          margin-left: 0 !important;
        }
        .header__escudo {
          display: none;
        }
      }
    }
  }
  .header__navigation {
    display: flex !important;
    flex-wrap: wrap;

    li {
      padding: 0.5em !important;

      a {
        padding: 0.5em !important;
      }
    }
  }
  .section__container {
    .background__modal {
      .modal__contents {
        margin: 5% auto !important;

        h2 {
          margin-top: 1em;
        }
      }
    }
  }
}

/* Desktops and laptops ----------- */
@media only screen and (max-width: 810px) {
  /* Styles */
  .services#servicios {
    .services__container {
      grid-template-columns: 1fr !important;

      .services_service {
        max-height: 100% !important;
      }
    }
  }
  section {
    .h1 {
      font-size: 1em !important;
    }
  }
}

/* Desktops and laptops ----------- */
@media only screen and (max-width: 830px) {
  /* Styles */
  .header__container {
    .header__amikoos {
      .cats__grandes,
      .dogs__medianas,
      .dogs__grandes {
        display: none;
      }
    }
    .header__navigation {
      display: flex;
      justify-content: center;
      width: auto !important;
      margin: 0 !important;
    }
  }
}

/* Desktops and laptops ----------- */
@media only screen and (max-width: 1000px) {
  /* Styles */
  .header__container {
    .header__amikoos {
      .cats__medianas {
        display: none;
      }
    }
  }
  .acerca {
    .section__container {
      h1,
      .descriptionContainer {
        grid-column: 4/4 !important;
      }
      .imgContainer img {
        display: none;
      }
    }
  }
}

/* Desktops and laptops ----------- */
@media only screen and (max-width: 1170px) {
  /* Styles */
  .header__container {
    .header__amikoos {
      .cats__comunes,
      .dogs__pequenas {
        display: none;
      }
    }
  }
}

/* Desktops and laptops ----------- */
@media only screen and (max-width: 1200px) {
  /* Styles */
  .services {
    .services__container {
      grid-template-columns: repeat(2, 1fr) !important;

      .services_service {
        max-height: 410px !important;
        img {
          width: 50% !important;
        }
      }
    }
  }
  .background__modal {
    .modal__contents {
      margin: 1% !important;
      padding: 0.7em !important;
      width: 95% !important;
    }
  }
}

/* Desktops and laptops ----------- */
@media only screen and (max-width: 1300px) {
  /* Styles */
  .header__container {
    .header__amikoos {
      .header__dogs img:nth-child(1),
      .header__cats img:nth-child(2) {
        display: none;
      }
      .header__dogs {
        .dogs__pequenas {
          right: 73.5% !important;
        }
        .dogs__medianas {
          right: 82% !important;
        }
      }
      .header__cats {
        .cats__comunes {
          right: 19.5% !important;
        }
        .cats__medianas {
          right: 12.2% !important;
        }
        .cats__grandes {
          right: 3.5% !important;
        }
      }
    }
  }
}

/* Desktops and laptops ----------- */
@media only screen and (max-width: 1300px) {
  /* Styles */
  .header__container {
    .header__amikoos {
      .header__dogs img:nth-child(1),
      .header__cats img:nth-child(2) {
        display: none;
      }
    }
  }
}

/* Desktops and laptops ----------- */
@media only screen and (max-width: 1450px) {
  /* Styles */
  .header__container .header__amikoos .header__cats img:nth-child(1) {
    display: none;
  }
}

/* Desktops and laptops ----------- */
@media only screen and (max-width: 1590px) {
  /* Styles */
  .header__container
    .header__search--background
    .header___search
    .header__imgAndSearch {
    margin-left: 4% !important;
  }
}
