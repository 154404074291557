.header__navigation {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  list-style: none;
  background-color: $white;
  width: 70%;
  margin: 0 auto;

  li {
    padding-top: 2.3em;
    text-align: center;
    font-weight: 500;
    position: relative;

    a {
      color: $amikoosDenseBlue;
      padding: 0.5em 1.5em 1.5em;
      @include transition(transform, false, 0.3s);
      text-decoration: none;
      display: inline-block;

      &:after {
        content: "\f1b0";
        position: absolute;
        font-family: FontAwesome;
        opacity: 0;
        padding: 0 0.5em;
        transform: translateY(25px);
        @include transition(transform, opacity, 0.5s);
        font-size: 0.9em;
        line-height: 17px;
        margin-left: 0.3em;
      }

      &:hover {
        cursor: pointer;
        color: $amikoosDarkPink;
        text-indent: 0px;
        transform: translateY(-5px);

        &:after {
          opacity: 1;
          color: $amikoosPink;
          transform: translateY(0);
        }
      }

      &:active {
        color: $darkerAmikoos;
      }
    }
  }
}
