.header__container {
  .header__search--background {
    background-color: $lightAmikoos;
    position: relative;
    z-index: 100;

    .header___search {
      display: grid;
      grid-template-columns: 1fr 2fr;
      height: 7.5em;
      align-items: center;
      width: 70%;
      margin: 0 auto;

      .header__imgAndSearch {
        display: grid;
        align-self: flex-start;
        padding-top: 1em;
        justify-content: start;
        margin-left: 11%;

        fieldset {
          position: relative;
          border: none;
          font-size: 15px;
          @include transition(font-size, false, 0.5s);
          margin-top: 0.9em;

          input {
            border-radius: 9999px;
            padding: 0.6em 2em;
            background-color: $ligtherAmikoos;
            border: 6px solid $lightAmikoos;
            width: 100%;
            font-size: inherit;

            &::placeholder {
              color: $amikoosDenseBlue;
              font-family: "Montserrat", sans-serif;
            }
          }

          button {
            position: absolute;
            right: 0.7em;
            top: 0.45em;
            font-size: 1.5em;
            border: none;
            background-color: transparent;
            cursor: pointer;
            outline: none;
            color: $amikoosDenseBlue;
            @include transition(color, false, 0.5s);

            &:hover {
              color: $amikoosBlue;
            }
          }

          &:hover {
            font-size: 16px;
          }
        }

        .header__img {
          max-width: 22em;
        }
      }

      .header__escudo {
        justify-self: end;

        img {
          border: 0.7em solid $lightAmikoos;
          border-radius: 50%;
          width: 150px;
        }
      }
    }
  }

  .header__amikoos {
    position: relative;
    top: -208px;
    z-index: 100;

    img {
      display: inline-block;
    }

    .header__dogs {
      img:nth-child(1) {
        @include animalPosition(absolute, 3.8em, 5.5em, 72%);
      }

      img:nth-child(2) {
        @include animalPosition(absolute, 2.8em, 6.3em, 78%);
      }

      img:nth-child(3) {
        @include animalPosition(absolute, 1.3em, 5em, 84%);
      }

      img:nth-child(4) {
        @include animalPosition(absolute, 0.9em, 6em, 89%);
      }
    }

    .header__cats {
      img:nth-child(1) {
        @include animalPosition(absolute, 3.55em, 5.9em, 29%);
      }

      img:nth-child(2) {
        @include animalPosition(absolute, 2.6em, 6em, 23%);
      }

      img:nth-child(3) {
        @include animalPosition(absolute, 3.35em, 5.2em, 17.5%);
      }

      img:nth-child(4) {
        @include animalPosition(absolute, 1.2em, 4.5em, 12.5%);
      }

      img:nth-child(5) {
        @include animalPosition(absolute, 0.9em, 5.5em, 6.5%);
      }
    }
  }
}
