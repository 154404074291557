$white: #fff;
$black: #000;
$lightAmikoos: #cce6e5;
$ligtherAmikoos: #eaf9f9;
$darkerAmikoos: #01a89e;
$amikoosBlue: #15d1e4;
$amikoosDenseBlue: #0099a9;
$amikoosPink: #f5c2d3;
$amikoosDarkPink: #ebb5c7;
$amikoosLightPurple: #bb71e6;
$amikoosPurple: #a531d3;
