.reviews {
  background-color: $ligtherAmikoos;
  @include backgroundPattern("./images/parallax/amikoos-pattern.png");

  .section__container {
    width: 90%;
    margin: 0 auto;

    .iTulOZ {
      max-width: 100%;
    }

    .eapps-widget-toolbar-panel-share-button,
    .eapps-widget:hover .eapps-widget-toolbar-panel,
    .eapps-widget-toolbar-panel-only-you,
    .eZJtJW + div {
      display: none;
    }

    .bjnEAu {
      cursor: pointer;
      font-family: "Montserrat", sans-serif;
      font-weight: bold;
      font-size: 1em;
      color: #ebb5c7;
      background-color: $ligtherAmikoos;
      border: 5px solid $lightAmikoos;
      margin-top: 2em;

      &:hover {
        color: $amikoosDenseBlue;
        background-color: $lightAmikoos;
      }
    }
  }
}
