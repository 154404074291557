.contacto {
  @include backgroundPattern("./images/parallax/contacto.png");

  .section__container {
    background-color: rgba(230, 230, 250, 0.7);

    h1 {
      color: $white;
      text-shadow: 2px 2px 2px #000;
    }

    .contact-info {
      padding: 2em 0;
      text-align: center;

      address {
        ul {
          .contacto-facebook,
          .contacto-instagram {
            display: inline-block;
            margin: 0 1em;

            span {
              font-size: 0.7em;
              margin-left: 0.3em;
            }
          }
          .contacto-whatsapp {
            i {
              font-size: 1.3em;
              margin-right: 0.5em;
            }
          }
          li {
            padding: 0.7em 0;
            a {
              color: $black;
              font-size: 1.5em;
              text-decoration: none;
              i {
                color: $black;
                font-size: 2em;
              }
              &:hover {
                color: $amikoosLightPurple;
              }
            }
          }
        }
      }
    }

    .map-iframe {
      width: 100%;
      height: 30em;
    }
  }
}
