.acerca {
  background-color: #fff;
  padding-bottom: 5em;

  .section__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    h1 {
      grid-column: 1/4;
      margin-bottom: 2em;
    }

    .imgContainer {
      grid-column: 1/2;
      img {
        width: 95%;
      }
    }

    .descriptionContainer {
      grid-column: 2/4;
      p {
        @include bodyTexts(1.1em);
        text-align: justify;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}
