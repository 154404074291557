@import "./variables.scss";
@import "./mixins.scss";
@import "./mediaqueries.scss";
@import "./components/header.scss";
@import "./components/navigation.scss";
@import "./components//slider.scss";
@import "./components/footer.scss";
@import "./components/reviews.scss";
@import "./components/services.scss";
@import "./components/contacto.scss";
@import "./components/about.scss";
@import "./components/utils/modal.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
}

input {
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: 2px solid #00a99d;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}

section {
  .section__container {
    padding: 2em 0 3em;
    width: 90%;
    margin: 0 auto;
  }

  h1,
  .fsghtg {
    text-align: center;
    font-weight: 700;
    font-size: 2.5em;
    color: #0099a9;
    border-bottom: 5px solid $amikoosDarkPink;
    border-radius: 0.3em;
    width: fit-content;
    margin: 1em auto 0.5em;
    padding-bottom: 0.1em;
  }
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}
