.slider {
  background-color: $ligtherAmikoos;
  @include backgroundPattern("./images/parallax/amikoos-pattern.png");

  .slider__container {
    img {
      max-width: 34em;
      padding: 1em;
    }
  }
}
