.background__modal {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
  position: fixed;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 100;

  .modal__contents {
    @include borderRaius(10px);
    @include boxshadow1();
    background: $white;
    margin: 0 auto;
    margin-top: 5%;
    padding: 1em;
    width: 80%;

    h2 {
      text-align: center;
    }
    a,
    p {
      margin-top: 2em;
      padding: 1em;
    }
    ul {
      padding-left: 1em;

      li {
        list-style-type: disc;
        margin-left: 1em;
      }
    }
    p {
      text-align: justify;
    }
    a {
      color: $amikoosDenseBlue;
      display: inline-block;

      &:hover {
        color: $amikoosDarkPink;
      }
      &:active {
        color: $darkerAmikoos;
      }
    }
    button {
      @include borderRaius(50px);
      @include boxshadow2();
      border: 1px solid $lightAmikoos;
      cursor: pointer;
      font-weight: bold;
      padding: 0.5em 0.7em;

      &:hover {
        background-color: $darkerAmikoos;
        box-shadow: none;
        border: 1px solid #999;
        color: $white;
      }
    }
  }
}
