.services {
  @include backgroundParallax("./images/parallax/services.png");

  h1 {
    color: $white;
    text-shadow: 2px 2px 2px $black;
  }

  .services__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-items: center;
    align-items: center;
    grid-gap: 1em;

    .services_service {
      height: auto;
      background: rgb(165, 49, 211);
      background: linear-gradient(
        0deg,
        rgba(165, 49, 211, 0.8239670868347339) 0%,
        rgba(212, 184, 255, 0.7539390756302521) 20%,
        rgba(228, 247, 247, 0.8995973389355743) 50%
      );
      padding: 3em;
      margin-bottom: 3em;
      max-height: 100%;
      width: 90%;
      text-align: center;
      border-radius: 25px;
      position: relative;
      overflow: hidden;

      h2 {
        @include bodyTexts(1.7em);
      }

      img {
        -webkit-clip-path: circle(50% at 50% 50%);
        clip-path: circle(50% at 50% 50%);
        width: 60%;
        background-color: $white;
        padding: 2em;
        min-width: 10em;
        transform: scale(1);
        @include transition(transform, false, 0.6s);
      }

      &:hover img {
        transform: scale(0.9);
      }

      button {
        background-color: $amikoosPurple;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1.5em 0;
        outline: none;
        border: none;
        color: $white;
        font-weight: 600;
        font-size: 1em;
        cursor: pointer;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: $amikoosLightPurple;
          border-top: 2px solid $amikoosPurple;
        }
      }
    }
    .accesorios {
      img {
        background: none;
        padding: 0;
        width: 77%;
      }
      h2 {
        margin: 0.3em 0 2em 0;
      }
    }
  }
}
